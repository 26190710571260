import { useLocation } from "react-router-dom";
import logo from "../../assets/pics/logo.svg";
import menu from "../../assets/icons/menu.svg";
import style from "./header.module.scss";
import { useState } from "react";

const Header = () => {
  const pathname = useLocation().pathname;

  const [isExpand, setIsExpand] = useState(false);

  const toggleMenu = () => setIsExpand((s) => !s);

  console.log(pathname);

  return (
    <>
      <div className={style.main}>
        <div className={style.container}>
          <a href="/" className={style.logoContainer}>
            <img src={logo} alt="" />
          </a>
          <div className={style.linksContainer}>
            <a className={pathname === "/" ? style.activeLink : ""} href="/">
              Startseite
            </a>
            <a
              className={
                pathname === "/results-&-schedule" ? style.activeLink : ""
              }
              href="/results-&-schedule"
            >
              Spielplan und Tabelle
            </a>
            <a
              className={pathname === "/ruls" ? style.activeLink : ""}
              href="/ruls"
            >
              Turnierregeln
            </a>
            <a
              className={pathname === "/stadiums" ? style.activeLink : ""}
              href="/stadiums"
            >
              Sporthallen
            </a>
          </div>
          <span
            onClick={() => setIsExpand(false)}
            className={style.dropBack}
            style={
              isExpand ? { opacity: "25%" } : { opacity: "0", display: "none" }
            }
          ></span>

          <button onClick={toggleMenu} className={style.menuBtn}>
            <img src={menu} alt="" />
          </button>
          <div
            className={style.expandable}
            style={isExpand ? {} : { transform: "translatex(100%)" }}
          >
            <span className={style.expandableItemsContainer}>
              <a className={pathname === "/" ? style.activeLink : ""} href="/">
                Startseite
              </a>
              <a
                className={
                  pathname === "/results-&-schedule"
                    ? style.activeLink
                    : style.item
                }
                href="/results-&-schedule"
              >
                Spielplan und Tabelle
              </a>
              <a
                className={pathname === "/ruls" ? style.activeLink : ""}
                href="/ruls"
              >
                Turnierregeln
              </a>
              <a
                className={pathname === "/stadiums" ? style.activeLink : ""}
                href="/stadiums"
              >
                Sporthallen
              </a>

              {/* <a
            className={path === "/news" ? style.activeItem : style.item}
            href="/news"
          >
            News
          </a> */}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
