import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ModalState {
  isOpen: boolean;
  delayForClose: number;
  title: string;
  text: string;
  remainingTime: number;
  isForConfirm: boolean;
  onSubmit: () => void;
  closeBtn: boolean;
}

const initialState: ModalState = {
  isOpen: false,
  delayForClose: 0,
  title: "",
  text: "",
  remainingTime: 0,
  isForConfirm: false,
  onSubmit: () => {},
  closeBtn: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (
      state,
      action: PayloadAction<{
        delayForClose?: number;
        title: string;
        text: string;
        isForConfirm?: boolean;
        onSubmit?: () => void;
        closeBtn?: boolean;
      }>
    ) => {
      const noFn = () => {};
      state.delayForClose = action.payload.delayForClose || 0;
      state.remainingTime = action.payload.delayForClose || 0;
      state.text = action.payload.text;
      state.title = action.payload.title;
      state.isForConfirm = action.payload.isForConfirm || false;
      state.onSubmit = action.payload.onSubmit || noFn;
      state.closeBtn = action.payload.closeBtn || false;
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.delayForClose = 0;
      state.text = "";
      state.title = "";
      state.isForConfirm = false;
      state.onSubmit = () => {};
    },
    decreaseRemainingTime: (
      state,
      action: PayloadAction<{ amount: number }>
    ) => {
      state.remainingTime = state.remainingTime - action.payload.amount;
    },
  },
});

export default modalSlice.reducer;

export const { closeModal, showModal, decreaseRemainingTime } =
  modalSlice.actions;
