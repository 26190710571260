import StadiumCard from "../../components/stadiums/stadium-card/stadiumCard";
import style from "./stadiums.module.scss";
import dornbirn from "../../assets/pics/stadiums/dornbirn.png";
import graz from "../../assets/pics/stadiums/graz.png";
import baden from "../../assets/pics/stadiums/baden.png";
import fischamend from "../../assets/pics/stadiums/fischamend.png";
import villach from "../../assets/pics/stadiums/villach.png";
import eferding from "../../assets/pics/stadiums/eferding.png";
import cs from "../../assets/pics/stadiums/comming soon.png";

const Stadiums = () => {
  const stadiums = [
    {
      img: cs,
      city: "Steyr",
      date: "25. November",
      stadiumName: "Stadthalle Steyr",
      description: "Österreichs größte Hallenturnierserie mit insgesamt 16 ",
    },
    {
      img: dornbirn,
      city: "Dornbirn",
      stadiumName: "Messezentrum Dornbirn",
      date: "26. November",
      description: "Österreichs größte Hallenturnierserie mit insgesamt 16 ",
    },
    {
      img: cs,
      city: "Leonding",
      date: "2. Dezember",
      stadiumName: "Sporthalle Leonding",
      description: "Österreichs größte Hallenturnierserie mit insgesamt 16 ",
    },
    {
      img: graz,
      city: "Graz",
      date: "3. Dezember",
      stadiumName: "Augustinum Halle Graz",
      description: "Österreichs größte Hallenturnierserie mit insgesamt 16 ",
    },
    {
      img: villach,
      city: "Villach",
      date: "3. Dezember",
      stadiumName: "Sporthalle Viktring Klagenfurt",
      description: "Österreichs größte Hallenturnierserie mit insgesamt 16 ",
    },
    {
      img: baden,
      city: "Baden",
      date: "9. Dezember",
      stadiumName: "Sporthalle Baden",
      description: "Österreichs größte Hallenturnierserie mit insgesamt 16 ",
    },
    {
      img: fischamend,
      city: "Fischamend",
      date: "10. Dezember",
      stadiumName: "Fischamend",
      description: "Österreichs größte Hallenturnierserie mit insgesamt 16 ",
    },
    {
      img: cs,
      city: "Hausmannstätten",
      date: "16. Dezember",
      stadiumName: "Sporthalle Hausmannstätten",
      description: "Österreichs größte Hallenturnierserie mit insgesamt 16 ",
    },
    {
      img: villach,
      city: "Villach",
      date: "16. Dezember",
      stadiumName: "Sporthalle Villach",
      description: "Österreichs größte Hallenturnierserie mit insgesamt 16 ",
    },
    {
      img: eferding,
      city: "Eferding",
      date: "17. Dezember",
      stadiumName: "Sporthalle Eferding",
      description: "Österreichs größte Hallenturnierserie mit insgesamt 16 ",
    },
    {
      img: cs,
      city: "Rum",
      date: "17. Dezember",
      stadiumName: "Sporthalle Rum",
      description: "Österreichs größte Hallenturnierserie mit insgesamt 16 ",
    },
    {
      img: baden,
      city: "Baden",
      date: "7. Januar",
      stadiumName: "Sporthalle Baden",
      description: "Österreichs größte Hallenturnierserie mit insgesamt 16 ",
    },
  ];

  return (
    <>
      <div className={style.main}>
        {stadiums.map((stadium) => (
          <StadiumCard {...stadium} />
        ))}
      </div>
    </>
  );
};

export default Stadiums;
