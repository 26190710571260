import { createSlice } from "@reduxjs/toolkit";

interface LoadingState {
  showLoading: boolean;
}

const initialState: LoadingState = {
  showLoading: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.showLoading = true;
    },
    closeLoading: (state) => {
      state.showLoading = false;
    },
  },
});

export default loadingSlice.reducer;

export const { closeLoading, showLoading } = loadingSlice.actions;
