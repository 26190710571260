import style from "./stadiumCard.module.scss";
import calender from "../../../assets/icons/calendarRed.svg";

interface IProps {
  img: string;
  city: string;
  stadiumName: string;
  date: string;
  description: string;
}

const StadiumCard: React.FC<IProps> = (props) => {
  const { city, description, img, stadiumName, date } = props;
  return (
    <>
      <div className={style.main}>
        <img src={img} alt="stadium" />
        <div className={style.detailContainer}>
          <div>
            <h1>{city}</h1>
            <span>
              <img src={calender} alt="calender" />
              <h4>{date}</h4>
            </span>
          </div>
          <h2>{stadiumName}</h2>
          {/* <p>{description}</p> */}
        </div>
      </div>
    </>
  );
};
export default StadiumCard;
