import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { Suspense } from "react";
import AdminRouter from "./pages/admin/router";
import Ruls from "./pages/ruls/ruls";
import Stadiums from "./pages/stadiums/stadiums";
const Home = lazy(() => import("./pages/home"));

const Router = () => {
  return (
    <>
      <Suspense fallback={<>Loading ...</>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/results-&-schedule"
            element={
              <div
                style={{
                  margin: "100px auto",
                  textAlign: "center",
                }}
              >
                Coming soon ...
              </div>
            }
          />
          {/* <Route path="admin/*" element={<AdminRouter />} /> */}
          <Route path="ruls" element={<Ruls />} />
          <Route path="stadiums" element={<Stadiums />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Router;
