import React from "react";
import style from "./App.module.scss";
import Home from "./pages/home";
import Header from "./components/header";
import Footer from "./components/footer/footer";
import Router from "./router";
import { RouterProvider, useLocation } from "react-router-dom";

function App() {
  const path: string = useLocation().pathname.toLowerCase();

  const hideNavbarIn: any[] = [/\/admin\/*/];

  const hideFooterIn: any[] = [/\/admin\/*/];

  return (
    <>
      <div className={style.main}>
        <div className={style.container}>
          {!hideNavbarIn.some((e) => e?.test(path)) ? <Header /> : null}
          <Router />
          {!hideFooterIn.some((e) => e?.test(path)) ? <Footer /> : null}
        </div>
      </div>
    </>
  );
}

export default App;
