import style from "./footer.module.scss";
import call from "../../assets/icons/call.svg";
import logo from "../../assets/pics/logo.svg";
import sms from "../../assets/icons/sms.svg";
import location from "../../assets/icons/location.svg";
const Footer = () => {
  return (
    <>
      <div className={style.main}>
        <div className={style.top}>
          <img className={style.logo} src={logo} alt="" />
          <div className={style.middle}>
            <span>
              <h3>In Verbindung treten</h3>
              <a href="https://www.goldensports.at/#contactus">Kontakt</a>
              <a href="https://www.goldensports.at/impressum">Impressum</a>
              <div className="opacity-60">Newsletter</div>
            </span>
            <span>
              <h3>Über die Austria Tour</h3>
              {/* <div>Geschichte</div> */}
              <a href="https://www.goldensports.at/events">
                Golden Sports Events
              </a>
              <div className="opacity-60">Über uns</div>
              <div className="opacity-60">Das Team</div>
            </span>
          </div>

          <div className={style.right}>
            <h3>in Kontakt bleiben !</h3>
            {/* <span>
              <img src={call} alt="" />
              <span>+43 66411973537</span>
            </span> */}
            <span>
              <img src={sms} alt="" />
              <span>support@goldensports.at</span>
            </span>
            <span>
              <img src={location} alt="" />
              <span>Maulbertschgasse 7/5, 1190 Wien, Austria</span>
            </span>
          </div>
        </div>
        <div className={style.powered}>
          Powered by <span>Goldensports.at</span>
        </div>
      </div>
    </>
  );
};
export default Footer;
