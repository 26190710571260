"use client";

import React, { useState, useEffect, useRef } from "react";
import style from "./modal.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  closeModal,
  decreaseRemainingTime,
} from "../../redux/slices/modalSlice";

const Modal: React.FC = () => {
  const modalState = useAppSelector((state) => state.modal);

  const {
    delayForClose,
    isOpen,
    text,
    title,
    remainingTime,
    isForConfirm,
    onSubmit,
    closeBtn,
  } = modalState;

  const dispatch = useAppDispatch();

  const onClose = () => dispatch(closeModal());

  useEffect(() => {
    let intervalId: any;

    if (isOpen && delayForClose > 0 && remainingTime > 0) {
      intervalId = setInterval(() => {
        dispatch(decreaseRemainingTime({ amount: 10 }));
      }, 10);

      return () => clearInterval(intervalId);
    }
  }, [isOpen]);

  useEffect(() => {
    // If remainingTime becomes 0, close the modal
    if (delayForClose > 0 && remainingTime === 0) {
      onClose();
    }
  }, [remainingTime]);

  const handleSubmit = () => {
    onClose();
    if (onSubmit) {
      onSubmit();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      id="modal-container"
      className={style["modal-container"]}
      style={
        delayForClose > 0
          ? {
              animationDuration: `${delayForClose}ms`,
              // animationName: "modal-animation-open-close",
            }
          : { animationDuration: "0ms", top: "5%" }
      }
    >
      <div className={style["modal-content"]}>
        <h2>{title}</h2>
        <p>{text}</p>
        {isForConfirm ? (
          <div className={style["modal-buttons"]}>
            <button className={style.PrimaryButton} onClick={handleSubmit}>
              Submit
            </button>
            <button className={style.SecButton} onClick={onClose}>
              Close
            </button>
          </div>
        ) : null}
        {closeBtn && (
          <div className={style["modal-buttons"]}>
            <button onClick={onClose} className={style.PrimaryButton}>
              Got it
            </button>
          </div>
        )}
        {delayForClose > 0 && (
          <div className={style["progress-bar-container"]}>
            <div
              className={style["progress-bar"]}
              style={{ width: `${(remainingTime / delayForClose) * 100}%` }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
